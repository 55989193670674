import {
  setOpenNoMixedBasketModal,
  setShowBasket,
} from '../redux/actions/basket'
import {
  ADD_MULTIPLE_PRODUCT_FAILURE,
  ADD_MULTIPLE_PRODUCT_REQUEST,
  ADD_MULTIPLE_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  CREATE_CART_FAILURE,
  CREATE_CART_REQUEST,
  CREATE_CART_SUCCESS,
  CREATE_SUBSCRIPTION_CART_FAILURE,
  CREATE_SUBSCRIPTION_CART_REQUEST,
  CREATE_SUBSCRIPTION_CART_SUCCESS,
  DELETE_CART_DP_FAILURE,
  DELETE_CART_DP_REQUEST,
  DELETE_CART_DP_SUCCESS,
  DELETE_CART_FAILURE,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SECONDHAND_FAILURE,
  DELETE_PRODUCT_SECONDHAND_REQUEST,
  DELETE_PRODUCT_SECONDHAND_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  EMPTY_CART,
  GET_CART_FAILURE,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  PATCH_CART_DATES_FAILURE,
  PATCH_CART_DATES_REQUEST,
  PATCH_CART_DATES_SUCCESS,
  PUT_CART_COUPON_FAILURE,
  PUT_CART_COUPON_REQUEST,
  PUT_CART_COUPON_SUCCESS,
  PUT_PRODUCT_FAILURE,
  PUT_PRODUCT_REQUEST,
  PUT_PRODUCT_SUCCESS,
  SET_ADDRESS_TYPE_CART,
  UPDATE_CART_SUBSCRIPTION_FAILURE,
  UPDATE_CART_SUBSCRIPTION_REQUEST,
  UPDATE_CART_SUBSCRIPTION_SUCCESS,
} from '@/redux/actions/cart'
import axiosWrapper from '../redux/helpers/api'
import { EMethods, ERequestUrl } from '@/redux/helpers/requests'
import {
  CartContent,
  GAEvent,
  GTMEvent,
  ProductInCart,
  ProductToAdd,
  SecondHandParams,
} from '@/typings/base'
import { useDispatch, useSelector } from 'react-redux'
import { LOCAL_STORAGE_KEYS, ORDER_ITEM_TYPE } from '@/utils/constants'
import { useSnackbar } from 'notistack'
import { apiHeaders } from '@/utils/utils'
import { Dayjs } from 'dayjs'
import { useCallback } from 'react'
import {
  handleGACartEventWithSingleProduct,
  hangleGACartEventWithMultipleProducts,
  handleGTMAddToCart,
} from '@/utils/gtag'

enum SnackBarMessage {
  ADD = 'add',
  ADD_TRYOUT = 'add tryout',
  MAX = 'max',
  REMOVE = 'remove',
  DELETE = 'delete',
  LIMIT = 'limit',
  ADD_MULTIPLE = 'add-multiple',
}

const googleAnalyticsId = process.env.NEXT_PUBLIC_GA_ID
const googleTagManagerId = process.env.NEXT_PUBLIC_GTM_ID
const noMixedBasket = process.env.NO_MIXED_BASKET

function getMixedBasketCondition(items: ProductInCart[], data: ProductToAdd) {
  if (!noMixedBasket) return false

  const cartProductType = items.length && items[0].type
  const addRentalInSecondHandCart =
    cartProductType === ORDER_ITEM_TYPE.SECOND_HAND && !data.secondHand
  const addSecondHandInRentalCart =
    !(cartProductType === ORDER_ITEM_TYPE.SECOND_HAND) && data.secondHand

  return (
    cartProductType && (addRentalInSecondHandCart || addSecondHandInRentalCart)
  )
}

function getAddedProduct(data: ProductToAdd, response: CartContent) {
  const { productCode, variantCode } = data
  const withTryingOn = data.withTryingOn || false
  const product = response.items.find(
    (item) =>
      item.product.code === productCode &&
      item.withTryingOn === withTryingOn &&
      (item.product.isSimpleProduct ||
        item.product.variants[0].code === variantCode)
  )
  return product
}

function getPuid(data: ProductToAdd) {
  const { units } = data
  return units && units[0].puid
}

function getMaximumItemsInCart(
  items: ProductInCart[],
  maximumItemsInCart: number
) {
  const itemsInCartNumber = items.reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity,
    0
  )

  return itemsInCartNumber === maximumItemsInCart
}

export function useCart() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const cart = useSelector((state) => state.cart.item)
  const maximumItemsInCart = useSelector(
    (state) => state.parameters?.items?.maximumItemsInCart
  )

  const token = cart?.tokenValue
  const items = cart?.items || []

  async function addProductToCart(data: ProductToAdd) {
    const isMaximumItemsInCart = getMaximumItemsInCart(
      items,
      maximumItemsInCart
    )

    if (isMaximumItemsInCart) {
      enqueueSnackbar({ message: SnackBarMessage.LIMIT })
      return
    }

    const isBasketMixed = getMixedBasketCondition(items, data)
    if (isBasketMixed) {
      dispatch(setOpenNoMixedBasketModal(true))
      return
    }

    dispatch(ADD_PRODUCT_REQUEST())
    try {
      const response = await axiosWrapper({
        method: EMethods.post,
        url: ERequestUrl.createCart,
        uid: token + '/items',
        data,
      })
      if (googleAnalyticsId) {
        handleGACartEventWithSingleProduct(
          response,
          getAddedProduct(data, response),
          GAEvent.ADD_TO_CART,
          getPuid(data)
        )
      }
      if (googleTagManagerId) {
        handleGTMAddToCart(
          response,
          getAddedProduct(data, response),
          GTMEvent.ADD_TO_CART
        )
      }
      enqueueSnackbar({
        message: data.withTryingOn
          ? SnackBarMessage.ADD_TRYOUT
          : SnackBarMessage.ADD,
      })
      dispatch(setShowBasket(true))
      dispatch(ADD_PRODUCT_SUCCESS(response))
    } catch (error) {
      dispatch(ADD_PRODUCT_FAILURE(error))
    }
  }

  async function changeProductQuantityInCart(
    itemId: number,
    data: { quantity: number },
    gaEvent: GAEvent,
    stock: number
  ) {
    const isMaximumItemsInCart = getMaximumItemsInCart(
      items,
      maximumItemsInCart
    )
    if (gaEvent === GAEvent.ADD_TO_CART && isMaximumItemsInCart) {
      enqueueSnackbar({ message: SnackBarMessage.LIMIT })
      return
    }

    if (gaEvent === GAEvent.ADD_TO_CART && data.quantity > stock) {
      enqueueSnackbar({ message: SnackBarMessage.MAX })
      return
    }

    dispatch(PUT_PRODUCT_REQUEST())
    try {
      const response = await axiosWrapper({
        method: EMethods.put,
        url: ERequestUrl.createCart,
        uid: token + '/items/' + itemId,
        data,
      })
      if (googleAnalyticsId) {
        const product = response.items.find((item) => item.id === itemId)
        handleGACartEventWithSingleProduct(response, product, gaEvent)
      }

      if (googleTagManagerId) {
        const product = response.items.find((item) => item.id === itemId)
        handleGTMAddToCart(response, product, gaEvent as unknown as GTMEvent)
      }
      enqueueSnackbar({
        message:
          gaEvent === GAEvent.ADD_TO_CART
            ? SnackBarMessage.ADD
            : SnackBarMessage.REMOVE,
      })
      dispatch(setShowBasket(true))
      dispatch(PUT_PRODUCT_SUCCESS(response))
    } catch (error) {
      dispatch(PUT_PRODUCT_FAILURE(error))
    }
  }

  const removeProductFromCart = useCallback(
    async (
      product: ProductInCart,
      secondHandParams?: SecondHandParams,
      quantityToRemove = 1
    ) => {
      dispatch(
        secondHandParams
          ? DELETE_PRODUCT_SECONDHAND_REQUEST()
          : DELETE_PRODUCT_REQUEST()
      )

      try {
        const response = await axiosWrapper({
          method: EMethods.delete,
          url: ERequestUrl.createCart,
          uid: token + '/items/' + product.id,
          data: secondHandParams,
        })
        if (googleAnalyticsId) {
          handleGACartEventWithSingleProduct(
            response,
            product,
            GAEvent.REMOVE_FROM_CART,
            secondHandParams && secondHandParams.puid,
            quantityToRemove
          )
        }
        if (googleTagManagerId) {
          handleGTMAddToCart(
            response,
            product,
            GTMEvent.REMOVE_FROM_CART,
            quantityToRemove
          )
        }
        dispatch(
          secondHandParams
            ? DELETE_PRODUCT_SECONDHAND_SUCCESS(response)
            : DELETE_PRODUCT_SUCCESS(response)
        )
        dispatch(setShowBasket(true))
        enqueueSnackbar({ message: SnackBarMessage.DELETE })
      } catch (error) {
        dispatch(
          secondHandParams
            ? DELETE_PRODUCT_SECONDHAND_FAILURE(error)
            : DELETE_PRODUCT_FAILURE(error)
        )
      }
    },
    [token]
  )

  const deleteCart = useCallback(
    async (callOnDatePicker = false) => {
      dispatch(
        callOnDatePicker ? DELETE_CART_DP_REQUEST() : DELETE_CART_REQUEST()
      )

      localStorage.removeItem('CartToken')
      if (googleAnalyticsId) {
        hangleGACartEventWithMultipleProducts(GAEvent.REMOVE_FROM_CART, cart)
      }

      try {
        const response = await axiosWrapper({
          method: EMethods.delete,
          url: ERequestUrl.createCart,
          uid: token,
        })
        dispatch(
          callOnDatePicker
            ? DELETE_CART_DP_SUCCESS(response)
            : DELETE_CART_SUCCESS(response)
        )
        dispatch(setShowBasket(false))
      } catch (error) {
        dispatch(
          callOnDatePicker
            ? DELETE_CART_DP_FAILURE(error)
            : DELETE_CART_FAILURE(error)
        )
      }
    },
    [token]
  )

  const emptyCart = useCallback(() => {
    dispatch(EMPTY_CART())
    localStorage.removeItem('CartToken')
  }, [])

  const createCart = useCallback(
    async (startDate: Dayjs, endDate: Dayjs, warehouseId: number) => {
      dispatch(CREATE_CART_REQUEST())
      try {
        const response = await axiosWrapper({
          method: EMethods.post,
          url: ERequestUrl.createCart,
          data: {
            startRentingDate: startDate.format('DD/MM/YYYY'),
            endRentingDate: endDate.format('DD/MM/YYYY'),
            warehouseId,
          },
        })
        dispatch(CREATE_CART_SUCCESS(response))
      } catch (error) {
        dispatch(CREATE_CART_FAILURE(error))
      }
    },
    []
  )

  const createSubscriptionCart = useCallback(
    async (
      startDate: Dayjs,
      durationInMonth: number,
      warehouseId: number,
      customLocale?: any
    ) => {
      dispatch(CREATE_SUBSCRIPTION_CART_REQUEST())
      try {
        const response = await axiosWrapper({
          method: EMethods.post,
          url: ERequestUrl.createCart,
          data: {
            startRentingDate: startDate.format('DD/MM/YYYY'),
            durationInMonth,
            warehouseId,
          },
          customLocale,
        })
        dispatch(CREATE_SUBSCRIPTION_CART_SUCCESS(response))
      } catch (error) {
        dispatch(CREATE_SUBSCRIPTION_CART_FAILURE(error))
      }
    },
    []
  )

  const addMultipleProductToCart = useCallback(
    async (data: { items: any[] }) => {
      dispatch(ADD_MULTIPLE_PRODUCT_REQUEST())
      try {
        const response = await axiosWrapper({
          method: EMethods.post,
          url: ERequestUrl.createCart,
          uid: token + '/multiple-items',
          data,
        })
        enqueueSnackbar({ message: SnackBarMessage.ADD_MULTIPLE })
        setShowBasket(true)
        dispatch(ADD_MULTIPLE_PRODUCT_SUCCESS(response))
      } catch (error) {
        dispatch(ADD_MULTIPLE_PRODUCT_FAILURE(error))
      }
    },
    [token]
  )

  const getCart = useCallback(async () => {
    const token = localStorage.getItem('CartToken')
    dispatch(GET_CART_REQUEST())
    try {
      const response = await axiosWrapper({
        method: EMethods.get,
        url: ERequestUrl.createCart,
        uid: token,
      })
      dispatch(GET_CART_SUCCESS(response))
    } catch (error) {
      dispatch(GET_CART_FAILURE(error))
    }
  }, [token])

  const updateCartSubscription = useCallback(async () => {
    dispatch(UPDATE_CART_SUBSCRIPTION_REQUEST())
    try {
      const response = await axiosWrapper({
        method: EMethods.post,
        url: ERequestUrl.createCart,
        headers: apiHeaders(
          localStorage.getItem(LOCAL_STORAGE_KEYS.CONNEXION_TYPE)
        ),
        uid: token + '/auto-update',
      })
      dispatch(UPDATE_CART_SUBSCRIPTION_SUCCESS(response))
    } catch (error) {
      dispatch(UPDATE_CART_SUBSCRIPTION_FAILURE(error))
    }
  }, [token])

  const patchDatesCart = useCallback(
    async (data: {
      startRentingDate: string
      endRentingDate?: string
      oneDayDuration?: string
    }) => {
      dispatch(PATCH_CART_DATES_REQUEST())
      try {
        const response = await axiosWrapper({
          method: EMethods.patch,
          url: ERequestUrl.createCart,
          uid: token,
          data,
        })
        dispatch(PATCH_CART_DATES_SUCCESS(response))
      } catch (error) {
        dispatch(PATCH_CART_DATES_FAILURE(error))
      }
    },
    [token]
  )

  const putCoupon = useCallback(
    async (coupon: string) => {
      dispatch(PUT_CART_COUPON_REQUEST())
      try {
        const response = await axiosWrapper({
          method: EMethods.put,
          url: ERequestUrl.createCart,
          uid: token + '/coupon',
          data: {
            coupon: coupon,
          },
        })
        return dispatch(PUT_CART_COUPON_SUCCESS(response))
      } catch (error) {
        return dispatch(PUT_CART_COUPON_FAILURE(error))
      }
    },
    [token]
  )

  const setAddressTypeCart = useCallback((type: string) => {
    dispatch(SET_ADDRESS_TYPE_CART(type))
  }, [])

  return {
    addProductToCart,
    changeProductQuantityInCart,
    removeProductFromCart,
    deleteCart,
    emptyCart,
    createCart,
    createSubscriptionCart,
    addMultipleProductToCart,
    getCart,
    updateCartSubscription,
    patchDatesCart,
    putCoupon,
    setAddressTypeCart,
  }
}
